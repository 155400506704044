import React, { useState } from "react";
import EyeClose from "../../assets/svgs/EyeClose";
import EyeOpen from "../../assets/svgs/EyeOpen";
import Styles from "./index.module.scss";
interface ITextInput {
  value: any;
  name: any;
  placeholder?: string;
  onChange?: any;
  icon?: any;
  type?: string;
  error?: any;
  title?: string;
  paddingRight?: string;
  paddingLeft?: string;
  disabled?: boolean;
}
const TextInput = ({
  value,
  placeholder,
  onChange,
  icon,
  type,
  paddingRight,
  paddingLeft,
  name,
  error,
  title,
  disabled,
}: ITextInput) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={Styles.wrapper}>
      {title && <div className={Styles.wrapper__inputTitle}>{title}</div>}

      <div
        className={
          error
            ? Styles.wrapper__inputContainerError
            : Styles.wrapper__inputContainer
        }
      >
        <input
          type={type === "password" && !showPassword ? "password" : "text"}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          style={{
            border: error ? "1px solid rgb(241, 37, 37)" : "",
            paddingLeft,
            paddingRight,
            background: disabled ? "#3a3a3a" : "",
          }}
          disabled={disabled}
        />
        {(type === "password" || icon) && (
          <div className={Styles.wrapper__inputRightIcon}>
            <span style={{ cursor: "pointer" }}>
              {icon ||
                (!showPassword ? (
                  <span onClick={() => setShowPassword(true)}>
                    <EyeOpen />
                  </span>
                ) : (
                  <span onClick={() => setShowPassword(false)}>
                    <EyeClose />
                  </span>
                ))}
            </span>
          </div>
        )}
      </div>
      {error && <div className={Styles.wrapper__error}>{error}</div>}
    </div>
  );
};

export default TextInput;

import { useRef } from "react";
import { useAppSelector } from "../../../redux/reducers/hooks";
import useGetQueryParams from "../../../utilities/helpers/useGetQueryParams";
import BlindLight from "./BlindLight";
import Styles from "./Home.module.scss";
import TopSounds from "./MyTopSound";
const Home = () => {
  const { userProfile } = useAppSelector((state) => state?.userProfileDetails);
  const { getQuery } = useGetQueryParams();

  const getSongId = getQuery("songId");
  const getSongTitle = getQuery("songTitle");
  // const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);

  // const myRef = useRef(null);

  // const executeScroll = () => scrollToRef(myRef);

  return (
    <div className={Styles.wrapper}>
      {userProfile?._id && (
        <>
          {" "}
          <div className={Styles.wrapper__stats}>
            SHOWING STATS FROM: <span>ALL TIME</span>
          </div>
          <div>
            <TopSounds
            // onClickSong={() => {
            //   executeScroll();
            // }}
            />
            {getSongTitle && (
              <div>
                <BlindLight title={getSongTitle} songId={getSongId} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Home;

import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PlayIcon from "../../../../assets/svgs/PlayIcon";
import StopIcon from "../../../../assets/svgs/StopIcon";
import Button from "../../../../components/appButton";
import TextInput from "../../../../components/TextInput";
import AudioTrimmerIncrementAndDec from "./AudioTrimmerIncrementAndDec";
import Styles from "./index.module.scss";
import { useAppSelector } from "../../../../redux/reducers/hooks";
import { formatToTimeString } from "../../../../utilities/helpers/decimalPartical";
import services from "../../../../controllers";
import useToast from "../../../../utilities/hooks/useToast";

interface IAudioWaveform {
  url: string;
  handleSubmitAudoji: any;
  createAudojiLoader: boolean;
  title?: string;
  lyricToEdit?: string;
  resetLyricsForm?: any;
}
const AudioWaveform = ({
  url,
  handleSubmitAudoji,
  createAudojiLoader,
  title,
  lyricToEdit,
  resetLyricsForm,
}: IAudioWaveform) => {
  const toast = useToast();
  const { isRefetchAudoji } = useAppSelector((state) => state?.uploadModal);
  const audioRef: any = useRef(null);
  const canvasRef: any = useRef(null);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(10); // Default end time
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [isDraggingStart, setIsDraggingStart] = useState(false);
  const [isDraggingEnd, setIsDraggingEnd] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(1000); // Default canvas width
  const [canvasHeight, setCanvasHeight] = useState(50); // Default canvas height
  const [isCounter, setIsCounter] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);

  // const [loading, setLoading] = useState(false);

  // Adjust canvas size based on window resizing
  useEffect(() => {
    const handleResize = () => {
      if (canvasRef.current) {
        setCanvasWidth(canvasRef.current.offsetWidth); // Get the width of the parent container
        setCanvasHeight(canvasRef.current.offsetWidth * (50 / 1000)); // Maintain aspect ratio
      }
    };

    // Call the function initially and also on window resize
    handleResize(); // Call once on mount to set initial size
    window.addEventListener("resize", handleResize); // Listen to window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup on unmount
    };
  }, []);

  // Load the audio and generate the waveform data
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.onloadedmetadata = () => {
        setDuration(audio.duration);
        setEndTime(audio.duration); // Set end time to the full duration
      };
    }
  }, []);

  // Play/Pause audio based on the isPlaying state
  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.currentTime = startTime; // Set the start time for playback
      audio.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Handle the mouse down to start dragging
  const handleMouseDown = (e: any, type: any) => {
    e.preventDefault(); // Prevent default to avoid unwanted scrolling
    if (type === "start") {
      setIsDraggingStart(true);
    } else if (type === "end") {
      setIsDraggingEnd(true);
    }
  };

  // Handle mouse movement to drag the slider
  const handleMouseMove = (e: any) => {
    setIsCounter(false);
    if (isDraggingStart || isDraggingEnd) {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const clientX = e.touches ? e.touches[0].clientX : e.clientX; // For touch, get clientX from the first touch
      const percent = (clientX - rect.left) / canvas.width;
      const newTime = percent * duration;

      // Ensure the start time doesn't go past the end time
      if (isDraggingStart) {
        const newStartTime = Math.min(newTime, endTime - 0.1); // Add a buffer of 0.1 seconds to avoid overlap

        setStartTime(Math.max(0, newStartTime)); // Ensure start time isn't less than 0
      } else if (isDraggingEnd) {
        const newEndTime = Math.max(newTime, startTime + 0.1); // Add a buffer of 0.1 seconds to avoid overlap

        setEndTime(Math.min(newEndTime, duration)); // Ensure end time doesn't go beyond the audio duration
      }
    }
  };

  const handleMouseUp = () => {
    setIsDraggingStart(false);
    setIsDraggingEnd(false);
  };

  const handleTimeUpdate = () => {
    const audio = audioRef.current;
    if (audio.currentTime >= endTime) {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const handleAddStartTime = () => {
    setIsCounter(true);
    if (startTime < 0 || startTime >= endTime - 1) {
      return;
    } else {
      setStartTime((prev) => prev + 0.01);
    }
  };

  const handleSubtractStartTime = () => {
    setIsCounter(true);
    if (startTime >= 0.1) {
      setStartTime((prev) => prev - 0.01);
    }
  };

  const handleAddEndTime = () => {
    setIsCounter(true);
    if (endTime >= duration) {
      return;
    }
    setEndTime((prev) => prev + 0.01);
  };

  const handleSubtractEndTime = () => {
    setIsCounter(true);
    if (startTime < endTime) {
      setEndTime((prev) => prev - 0.01);
    }
  };

  // const audojiValidationSchema = Yup?.object()?.shape({
  //   lyrics: Yup.string().required("Required"),
  // });

  const [audioBuffer, setAudioBuffer]: any = useState(null);

  const getBufferRequest = async () => {
    // Fetch and decode the audio data
    const audioContext = new AudioContext();
    const response = await fetch(url);
    const audioData = await response.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(audioData);
    return setAudioBuffer(audioBuffer);
  };

  useEffect(() => {
    getBufferRequest();
  }, []);
  const trimAudio = async () => {
    const audioContext = new AudioContext();

    // Calculate the start and end sample indices
    let newEndTime = isCounter ? endTime + 0.2 : endTime + 0.1;
    const startSample = Math.floor(startTime * audioBuffer.sampleRate);
    const endSample = Math.floor(newEndTime * audioBuffer.sampleRate);

    // Create a new buffer for the trimmed audio
    const trimmedBuffer = audioContext.createBuffer(
      audioBuffer.numberOfChannels,
      endSample - startSample,
      audioBuffer.sampleRate,
    );

    // Copy the audio data for each channel
    for (let channel = 0; channel < audioBuffer.numberOfChannels; channel++) {
      const channelData = audioBuffer.getChannelData(channel);
      trimmedBuffer.copyToChannel(
        channelData.subarray(startSample, endSample),
        channel,
      );
    }

    // Render the trimmed buffer into a Blob
    const offlineContext = new OfflineAudioContext(
      trimmedBuffer.numberOfChannels,
      trimmedBuffer.length,
      trimmedBuffer.sampleRate,
    );
    const source = offlineContext.createBufferSource();
    source.buffer = trimmedBuffer;
    source.connect(offlineContext.destination);
    source.start(0);

    const renderedBuffer = await offlineContext.startRendering();
    const audioBlob = new Blob([bufferToWave(renderedBuffer)], {
      type: "audio/wav",
    });

    return audioBlob;
  };

  // Helper function to convert an AudioBuffer to WAV format
  const bufferToWave = (audioBuffer: AudioBuffer) => {
    const numOfChan = audioBuffer.numberOfChannels;
    const length = audioBuffer.length * numOfChan * 2 + 44;
    const buffer = new ArrayBuffer(length);
    const view = new DataView(buffer);

    const channels = [];
    let offset = 0;

    // Write WAV header
    const writeUTFBytes = (view: DataView, offset: number, string: string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };
    writeUTFBytes(view, 0, "RIFF");
    view.setUint32(4, 36 + audioBuffer.length * numOfChan * 2, true);
    writeUTFBytes(view, 8, "WAVE");
    writeUTFBytes(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, numOfChan, true);
    view.setUint32(24, audioBuffer.sampleRate, true);
    view.setUint32(28, audioBuffer.sampleRate * 4, true);
    view.setUint16(32, numOfChan * 2, true);
    view.setUint16(34, 16, true);
    writeUTFBytes(view, 36, "data");
    view.setUint32(40, audioBuffer.length * numOfChan * 2, true);

    // Interleave
    for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
      channels.push(audioBuffer.getChannelData(i));
    }

    let pos = 44;
    for (let i = 0; i < audioBuffer.length; i++) {
      for (let channel = 0; channel < numOfChan; channel++) {
        const sample = Math.max(-1, Math.min(1, channels[channel][i]));
        view.setInt16(
          pos,
          sample < 0 ? sample * 0x8000 : sample * 0x7fff,
          true,
        );
        pos += 2;
      }
    }

    return buffer;
  };

  // const audojiFormik = useFormik({
  //   validationSchema: audojiValidationSchema,
  //   initialValues: {
  //     lyrics: lyricToEdit || "",
  //   },
  //   enableReinitialize: true,
  //   onSubmit: async (values: any) => {
  //     // endTime: isUseDragEffect
  //     // ? Math.trunc(endTime + 0.2)
  //     // : Math.trunc(endTime),
  //     let data = {
  //       startTime: Math.trunc(startTime),
  //       endTime: Math.trunc(endTime),
  //       start_m_s_h: Math.trunc(startTime),
  //       end_m_s_h: Math.trunc(endTime),
  //       // start_m_s_h: formatToTimeString(startTime),
  //       // end_m_s_h: formatToTimeString(endTime),
  //       lyrics: values?.lyrics,
  //     };

  //     console.log("endTime", endTime);

  //     // console.log("data", data);

  //     // handleSubmitAudoji(data);
  //   },
  // });

  const [lyrics, setLyrics] = useState("");
  const [lyricsError, setLyricsError] = useState("");

  useEffect(() => {
    if (isRefetchAudoji) {
      setLyrics("");
      setLyricsError("");
    }
  }, [isRefetchAudoji]);

  const [generatingLoader, setGeneratingLoader] = useState(false);

  const handleNewSubmit = async () => {
    if (!lyrics) {
      return setLyricsError("Required");
    }
    setGeneratingLoader(true);
    const audioBlob = await trimAudio();
    setGeneratingLoader(false);
    if (!audioBlob) {
      return toast.error(`an error occurr while generating audoji, try again.`);
    }

    let data = {
      startTime: startTime,
      endTime: endTime,
      lyrics: lyrics,
      audioBlob,
    };
    handleSubmitAudoji(data);
    setIsCounter(false);
  };

  const playActionBtn = (
    <button onClick={handlePlayPause}>
      {isPlaying ? <StopIcon /> : <PlayIcon />}
    </button>
  );

  // const handlePlayBlob = () => {
  //   if (audioBlob && audioRef.current) {
  //     const blobUrl = URL.createObjectURL(audioBlob);
  //     audioRef.current.src = blobUrl; // Set the blob URL as the source
  //     audioRef.current.play(); // Play the audio
  //   }
  // };

  return url ? (
    <div className={Styles.wrapper}>
      <div className={Styles.wrapper__title}>{title || ""}</div>

      <audio
        ref={audioRef}
        src={url}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={() => {
          setDuration(audioRef.current.duration);
        }}
        controls={false}
      />

      <div
        style={{ position: "relative", width: "100%", height: canvasHeight }}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseUp}
        onTouchCancel={handleMouseUp}
      >
        <div className={`${Styles.wrapper__waveBackground} `}>
          <div
            className={`${
              isPlaying && Styles.wrapper__isPlayingwaveBackground
            }`}
          >
            <canvas
              ref={canvasRef}
              width={canvasWidth}
              height={canvasHeight}
              style={{
                cursor: "pointer",
                width: "100%",
              }}
            />
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: `${(startTime / duration) * 100}%`,
            width: `${((endTime - startTime) / duration) * 100}%`,
            height: "100%",
            borderRadius: "15px",
            background:
              "linear-gradient(90deg, rgba(239, 65, 54, 0.6) 0%, rgba(251, 176, 64, 0.7) 100%)",
          }}
        ></div>

        {/* Custom range sliders */}
        <div
          className={`${Styles.wrapper__sliderHandle} ${Styles.wrapper__start}`}
          style={{ left: `${(startTime / duration) * 100}%` }}
          onMouseDown={(e) => handleMouseDown(e, "start")}
          onTouchStart={(e) => handleMouseDown(e, "start")}
        />
        <div
          className={`${Styles.wrapper__sliderHandle} ${Styles.wrapper__end}`}
          style={{ left: `${(endTime / duration) * 100}%` }}
          onMouseDown={(e) => handleMouseDown(e, "end")}
          onTouchStart={(e) => handleMouseDown(e, "end")}
        />
      </div>

      <div className={Styles.wrapper__mainSliderHandle}>
        <div className={Styles.wrapper__mainStartTimeSliderHandle}>
          <div className={Styles.wrapper__mainStartTimeLabel}>
            Start Time: {`${startTime?.toFixed(2)} Sec`}
          </div>
          <input
            type="range"
            min="0"
            max={duration}
            value={startTime}
            onChange={(e) => {
              setIsCounter(false);
              const newStartTime = parseFloat(e.target.value);
              // Ensure start time doesn't go beyond end time
              setStartTime(Math.min(newStartTime, endTime - 0.1));
            }}
          />
        </div>

        <div className={Styles.wrapper__mainEndTimeSliderHandle}>
          <div className={Styles.wrapper__mainEndTimeLabel}>
            End Time: {`${endTime?.toFixed(2)} Sec`}
          </div>
          <input
            type="range"
            min="0"
            max={duration}
            value={endTime}
            onChange={(e) => {
              setIsCounter(false);
              const newEndTime = parseFloat(e.target.value);
              // Ensure end time doesn't go beyond audio duration and start time
              setEndTime(Math.min(newEndTime, duration));
            }}
          />
        </div>
      </div>
      <div className={Styles.wrapper__lyricsContainer}>
        <TextInput
          name={"lyrics"}
          placeholder="Enter Audoji Lyrics"
          value={lyrics}
          onChange={(evt: any) => {
            setLyrics(evt?.target?.value);
            setLyricsError("");
          }}
          error={lyricsError}
        />
      </div>
      <div className={Styles.wrapper__actionsContainer}>
        <div className={Styles.wrapper__actionsForPlayAndControl}>
          <div className={Styles.wrapper__playContainer}>{playActionBtn}</div>
          <div className={Styles.wrapper__inputContainers}>
            <div className={Styles.wrapper__startTimeIncrementAndDecrement}>
              <AudioTrimmerIncrementAndDec
                onAdd={handleAddStartTime}
                onSubtract={handleSubtractStartTime}
                time={`${startTime.toFixed(2)}`}
              />
            </div>
            <div className={Styles.wrapper__endTimeIncrementAndDecrement}>
              <AudioTrimmerIncrementAndDec
                onAdd={handleAddEndTime}
                onSubtract={handleSubtractEndTime}
                time={`${endTime.toFixed(2)}`}
              />
            </div>
          </div>
        </div>
        <div className={Styles.wrapper__playAndCreateBtn}>
          <div className={Styles.wrapper__plaBtnForMobile}>
            <div className={Styles.wrapper__plaInnerBtnForMobile}>
              <button onClick={handlePlayPause}>
                <span>{isPlaying ? "Stop" : "play"}</span>
              </button>
            </div>
          </div>
          <div className={Styles.wrapper__createAudojiBtn}>
            <Button
              title="Create Audoji"
              onClick={handleNewSubmit}
              loading={generatingLoader || createAudojiLoader}
            />
          </div>
          {/* <button onClick={handlePlayBlob} disabled={!audioBlob}>
            Play Blob Audio
          </button> */}
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default AudioWaveform;

import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  setAudojiSearch,
  setMusicSearch,
} from "../../redux/reducers/appSearch";
import { setIsShowSidebarModal } from "../../redux/reducers/sidebarModal";
import Styles from "./AppLink.module.scss";
interface IAppLink {
  details: any;
}
const AppLink = ({ details }: IAppLink) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return details?.link ? (
    <Link to={details?.link} style={{ textDecoration: "none" }}>
      <div
        className={`${Styles.wrapper} ${details?.activeLink?.includes(pathname) ? Styles.container : Styles.inActiveContainer}`}
        onClick={() => {
          dispatch(setIsShowSidebarModal(false));
          dispatch(setMusicSearch(""));
          dispatch(setAudojiSearch(""));
        }}
      >
        <span>
          {details?.activeLink?.includes(pathname)
            ? details?.icon
            : details?.inactiveIcon}
        </span>{" "}
        {details?.title}
      </div>
    </Link>
  ) : (
    <div
      className={`${Styles.wrapper} ${details?.activeLink?.includes(pathname) ? Styles.container : Styles.inActiveContainer}`}
      onClick={() => {
        dispatch(setIsShowSidebarModal(false));
        sessionStorage.clear();
        navigate("/");

        dispatch(setMusicSearch(""));
        dispatch(setAudojiSearch(""));
        window.location.reload();
      }}
      style={{ color: details?.color }}
    >
      <span>
        {details?.activeLink?.includes(pathname)
          ? details?.icon
          : details?.inactiveIcon}
      </span>{" "}
      {details?.title}
    </div>
  );
};

export default AppLink;
